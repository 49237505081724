<template>
  <div>
    <v-card>
      <div class="search-card">
        <v-btn
          style="display: none"
          small
          class="close-btn-search"
          @click="resetSearch()"
          fab
          right
          absolute
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="search-box reference-page">
          <div class="search-inner-box">
            <div class="search-menu" style="width: 100% !important">
              <div class="filter-field-container search-block">
                <span class="codingChoiceLabel">
                  Conditions
                  <v-icon
                    color="black"
                    class="p-1"
                    size="12"
                    dark
                    v-tippy="{
                      theme: 'epicc',
                      arrow: true,
                      content: tooltipDataForCondition,
                    }"
                    >fas fa-question-circle</v-icon
                  >
                </span>
                <v-text-field
                  class="dxTextField"
                  placeholder="Search here"
                  color="#0099d9"
                  dense
                  flat
                  outlined
                  v-model="queryReset"
                  v-on:input="debounceInput"
                  autofocus
                ></v-text-field>
              </div>
              <div
                class="filter-field-container"
                v-bind:style="[
                  current_path != '/hccreference/'
                    ? current_path != '/'
                      ? { 'padding-left': '16px' }
                      : { width: '100%' }
                    : { width: '100%' },
                ]"
              >
                <span class="codingChoiceLabel">DX/HCC</span>
                <v-select
                  class="search-coding-choice"
                  flat
                  dense
                  color="#0099d9"
                  style="color: #545454"
                  outlined
                  :items="codingChoices"
                  v-model="codingValue"
                  @change="elasticSearch('typeChange')"
                />
              </div>
              <div class="filter-field-container">
                <span class="search-dos-choice">DOS Year/Model</span>
                <v-select
                  class="search-dos-choice"
                  flat
                  dense
                  outlined
                  color="#0099d9"
                  style="color: #545454"
                  :items="dosChoices"
                  v-model="dosValue"
                  @change="setDosYear"
                ></v-select>
              </div>
              <div class="filter-field-container">
                <span class="drfChoicelabel">Risk Model</span>
                <template v-if="hasHhsData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    dense
                    color="#0099d9"
                    outlined
                    :items="drfhhsItems"
                    v-model="DefaultdrfValue"
                    @change="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasV05Data">
                  <v-select
                    class="search-drf-choice"
                    flat
                    dense
                    color="#0099d9"
                    outlined
                    :items="drfv05Items"
                    v-model="DefaultdrfValue"
                    @change="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasv21ESRDData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    dense
                    color="#0099d9"
                    outlined
                    :items="drfEsrdv21Items"
                    v-model="DefaultdrfValue"
                    @change="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasv24ESRDData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    dense
                    color="#0099d9"
                    outlined
                    :items="drfEsrdv24Items"
                    v-model="DefaultdrfValue"
                    @change="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else>
                  <v-select
                    class="search-drf-choice"
                    flat
                    dense
                    color="#0099d9"
                    outlined
                    :items="drfItems"
                    v-model="DefaultdrfValue"
                    @change="elasticSearch('typeChange')"
                  ></v-select>
                </template>
              </div>
              <div class="filter-field-container age-block">
                <span class="drfChoicelabel">Age</span>
                <v-text-field
                  :value="age"
                  dense
                  outlined
                  type="number"
                  class="agetextfield"
                  @input="onChangeAgeInput"
                  color="#0099d9"
                  :error-messages="invalidAge ? ['Enter valid age'] : []"
                  autocomplete="off"
                ></v-text-field>
              </div>
            </div>
            <div class="search-result-box" @scroll="handleLazyLoading">
              <v-progress-circular
                indeterminate
                color="red"
                class="loaderStyleRef"
                v-if="isActive == true"
              ></v-progress-circular>

              <table class="search-table">
                <tr>
                  <th
                    v-if="isCodingValueSetToDX"
                    class="search-header-th-left search-inner-th-individual"
                    style="width: 19.1%"
                  >
                    DX Code
                  </th>
                  <th
                    v-if="isCodingValueSetToHCC"
                    class="search-header-th-left search-inner-th-individual"
                    style="width: 19.1%"
                  >
                    HCC
                    <span>
                      <v-icon
                        color="black"
                        class="p-1"
                        size="12"
                        dark
                        v-tippy="{
                          theme: 'epicc',
                          arrow: true,
                          content: tooltipDataForHccRef,
                        }"
                      >
                        fas fa-question-circle
                      </v-icon>
                    </span>
                  </th>
                  <th class="search-inner-th-individual" style="width: 38.6%">
                    Description
                  </th>
                  <th
                    v-if="isCodingValueSetToDX"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    HCC
                  </th>
                  <th
                    v-if="hasHhsData"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    Plate
                  </th>
                  <th
                    v-if="isCodingValueSetToHCC"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    Trump Codes
                  </th>
                  <th
                    class="search-inner-th-individual search-header-th-right"
                    style="width: 25.5%"
                  >
                    RAF
                  </th>
                  <th
                    class="search-header-th-right"
                    style="
                      width: 7.2%;
                      display: none;
                      border: none;
                      position: relative;
                      background: white !important;
                    "
                  ></th>
                </tr>

                <tr
                  v-for="item in tableData"
                  :key="item.code"
                  v-bind:class="{
                    selectedRow: item.code === selectedItem.code,
                  }"
                >
                  <td style="cursor: pointer">
                    <p class="search-table-left" v-if="isCodingValueSetToDX">
                      {{ item.code }}
                    </p>
                    <p v-if="isCodingValueSetToHCC" class="search-table-left">
                      <span @click="changeSearch(item.code)">
                        {{ item.code }}
                      </span>
                    </p>
                  </td>
                  <td class="text-xs-right" style="cursor: pointer">
                    <p class="search-table-left">{{ item.description }}</p>
                  </td>
                  <td v-if="isCodingValueSetToDX">
                    <span v-for="hcc in item.hcc" :key="hcc">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" @mouseover="showHccDetail(hcc)">
                            {{ hcc }}
                            <span
                              v-bind:style="[
                                item.hcc.length > 1
                                  ? item.hcc.lastIndexOf(hcc) ==
                                    item.hcc.length - 1
                                    ? { display: 'none' }
                                    : {}
                                  : { display: 'none' },
                              ]"
                              >,</span
                            >
                          </span>
                        </template>
                        <span>{{ onHoverData }}</span>
                      </v-tooltip>
                    </span>
                  </td>
                  <td v-if="hasHhsData">
                    <p class="search-table-left" v-if="isCodingValueSetToDX">
                      {{ item.plate }}
                    </p>
                  </td>
                  <td v-if="isCodingValueSetToHCC" class="text-xs-right">
                    <span v-for="trump in item.trumps" :key="trump">
                      <span>
                        {{ trump }}
                        <span
                          v-bind:style="[
                            item.trumps.length > 1
                              ? item.trumps.lastIndexOf(trump) ==
                                item.trumps.length - 1
                                ? { display: 'none' }
                                : {}
                              : { display: 'none' },
                          ]"
                          >,</span
                        >
                      </span>
                    </span>
                  </td>
                  <td>
                    <p class="search-table-right">{{ item.raf }}</p>
                  </td>
                  <td style="border: none; background-color: white">
                    <div
                      v-for="data in selectedDxData"
                      v-bind:key="data.code"
                      style="display: none"
                    >
                      <p class="tickmark" v-if="item.code == data.code">
                        <i class="fas fa-check"></i>
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style="display: none">
            <div class="selectedDxBox">
              <div
                v-if="selectedRowsIDs.length == 0"
                class="no-condition-selected"
              >
                <p>No Conditions Selected</p>
              </div>
              <div v-else class="selectDxTable">
                <p class="selected-condition">Selected Conditions</p>
                <p class="message-over-search-select">
                  Click
                  <i class="fas fa-times"></i> to remove condition from list.
                </p>
                <div class="selected-table">
                  <table class="selected-table-table">
                    <tr class="selected-table-head-tr">
                      <th
                        style="width: 29%; border-right: 1px solid white"
                        class="search-header-th-left"
                      >
                        Code
                      </th>
                      <th style="width: 59%" class="search-header-th-right">
                        Description
                      </th>
                      <th style="width: 10%; background: white"></th>
                    </tr>

                    <tr
                      v-for="item in selectedDxData"
                      :key="item.name"
                      class="selected-table-data-tr"
                    >
                      <td>{{ item.code }}</td>
                      <td class="text-xs-right">
                        {{ item.description.substring(0, 60) + '..' }}
                      </td>
                      <td>
                        <div
                          v-for="data in selectedDxData"
                          v-bind:key="data.code"
                          class="tickmark"
                        >
                          <p
                            v-if="item.code == data.code"
                            style="cursor: pointer"
                            @click="removeDx(item)"
                            :class="{
                              removeDxRow: item.code === selectedItem.code,
                            }"
                          >
                            <i class="fas fa-times"></i>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- <div class="confirm-selected-button-div"> -->
              <div style="text-align: right;">
                <v-btn
                  v-if="selectedRowsIDs.length != 0"
                  class="confirm-selected-button submit button"
                  color="info"
                  @click="fillSelectedDx"
                  >{{ confirm }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <MainFooter />
  </div>
</template>
<style scoped src="../../assets/css/search.css"></style>
<script>
import riskModelVItems from '../../data/riskModelVItemsSearch.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import dosYearData from '../../data/dosYearData.json'
import store from '../../store'
import MainFooter from '../common/MainFooter.vue'
import _ from 'lodash'
var LRU = require('lru-cache')
var options = {
  max: 500,
  length: function(n, key) {
    return n * 2 + key.length
  },
  maxAge: 1000 * 60 * 60,
}
var cache = new LRU(options)
export default {
  components: {
    MainFooter,
  },
  data() {
    return {
      age: 65,
      current_path: window.location.pathname,
      onHoverData: '',
      searchCodeData: [],
      searchTerm: '',
      queryReset: '',
      tooltipDataForHccRef: 'Click on Hcc to show all the related Dx Codes',
      codingChoices: [
        { text: 'DX', value: 'dx' },
        { text: 'HCC', value: 'hcc' },
      ],
      codingValue: 'dx', // default coding method
      dosChoices: dosYearData,
      buttonStyle: {
        cursor: 'default',
      },
      dosValue: 'v24_2022', // default DOS Value
      confirm: 'Confirm',
      dxTableHeaders: [
        {
          text: 'DX Code',
          sortable: false,
          value: 'code',
        },
        { text: 'Description', value: 'description' },
        { text: 'HCC', value: 'hcc' },
        { text: 'RAF', value: 'raf' },
        { text: '', value: '', class: 'blankColHeader' },
      ],
      local: [],
      dxData: [],
      selectedDxTableHeaders: [
        {
          text: 'DX Code',
          sortable: false,
          value: 'code',
        },
        { text: 'Description', value: 'description' },
      ],
      selectedDxData: [],
      selectedRowsIDs: [],
      selectedItem: -1,
      tableData: [],
      drfItems: riskModelVItems,
      drfv05Items: riskModelV05Items,
      drfhhsItems: riskModelHHSItems,
      drfEsrdv21Items: riskModelESRDV21Items,
      drfEsrdv24Items: riskModelESRDV24Items,
      DefaultdrfValue: 'CN',
      search_url: '/api/search',
      hover_url: '/api/search/hover',
      isActive: false,
      dialog: false,
      jsonTable: null,
      d: null,
      totalEntries: 0,
      endData: false,
      tooltipDataForCondition:
        'Conditions eligible as per selected dos year model.',
    }
  },

  computed: {
    hasHhsData() {
      return store.getters.hasHhsData(this.getDosValue(this.dosValue))
    },
    hasV05Data() {
      return store.getters.hasV05Data(this.getDosValue(this.dosValue))
    },
    hasv21ESRDData() {
      return store.getters.hasv21ESRDData(this.getDosValue(this.dosValue))
    },
    hasv24ESRDData() {
      return store.getters.hasv24ESRDData(this.getDosValue(this.dosValue))
    },
    isCodingValueSetToHCC() {
      return store.getters.hccCodingValue(this.codingValue)
    },
    isCodingValueSetToDX() {
      return store.getters.dxCodingValue(this.codingValue)
    },
    returnSearchDrfValue() {
      let defaultDrf = this.DefaultdrfValue
      let finalDrfValue = ''
      let search_v21_esrd = {
        C1: 'CG',
        C2: 'CG',
        D: 'CED',
        I2: 'IG',
        G1: 'KT',
        G2: 'KT',
        ED: 'NED',
        E2: 'E2',
      }
      let search_v24_esrd = {
        C3: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C4: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C5: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C6: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C7: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C8: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        D1: 'CED',
        D2: 'CED',
        ED: 'NED',
        E1: 'E1',
        E2: 'E2',
        G1: 'KT',
        G2: 'KT',
        I3: 'CEDI',
        I4: 'CEDI',
        I5: 'GI',
        I6: 'GI',
        I7: 'GI',
        I8: 'GI',
        I9: 'GI',
        IA: 'GI',
      }
      let search_v05 = {
        D1: parseInt(this.age) < 65 ? 'CNN' : 'CAN',
        D2: parseInt(this.age) < 65 ? 'CLN' : 'CLA',
        D3: 'INS',
        D4: 'NEN',
        D5: 'NEN',
        D6: 'NEL',
        D7: 'NEL',
        D8: 'NEI',
        D9: 'NEI',
      }
      let search_vData = {
        CN: parseInt(this.age) < 65 ? 'CND' : 'CNA',
        CP: parseInt(this.age) < 65 ? 'CPD' : 'CPA',
        CF: parseInt(this.age) < 65 ? 'CFD' : 'CFA',
        I: 'INS',
      }

      if (defaultDrf in search_vData) {
        finalDrfValue = search_vData[defaultDrf]
      } else if (defaultDrf in search_v05) {
        finalDrfValue = search_v05[defaultDrf]
      } else if (defaultDrf in search_v21_esrd) {
        finalDrfValue = search_v21_esrd[defaultDrf]
      } else if (defaultDrf in search_v24_esrd) {
        finalDrfValue = search_v24_esrd[defaultDrf]
      } else {
        finalDrfValue = defaultDrf
      }
      return finalDrfValue
    },
    invalidAge: {
      get: function() {
        parseInt(this.age) > 0 ? this.$emit('validAge', true) : false
        return this.isAgeInvalid
      },
      set: function(value) {
        this.isAgeInvalid = value
      },
    },
  },

  methods: {
    debounceInput: _.debounce(function(e) {
      if (e !== 'age') {
        this.searchTerm = e
        this.totalEntries = 0
        this.tableData = {}
        this.endData = false
      }
      this.elasticSearch()
    }, 500),
    showHccDetail(item) {
      /*This fucntion is using caching technique to show data on search hover 
      and also fetch data from /api/hover if data is not avail on its cache*/
      const item_hcc = item
      if (cache.keys().includes(item_hcc)) {
        this.onHoverData = cache.get(item_hcc)
      } else {
        let hover_data = {
          dx_hcc: 'hcc',
          dos_year: this.dosValue,
          drf: this.DefaultdrfValue,
          hcc: item_hcc,
        }
        this.axios.post(this.hover_url, hover_data).then((response) => {
          if (typeof response.data == 'object') {
            let data_recevied = Object.assign({}, response.data)
            this.onHoverData = data_recevied.description
            cache.set(data_recevied.code, data_recevied.description)
          }
        })
      }
    },

    changeTable() {
      if (this.codingValue == 'hcc') {
        this.$set(this.dxTableHeaders[0], 'text', 'HCC')
        this.$set(this.dxTableHeaders[2], 'text', 'Trump Codes')
      }
      if (this.codingValue == 'dx') {
        this.$set(this.dxTableHeaders[0], 'text', 'DX Code')
        this.$set(this.dxTableHeaders[2], 'text', 'HCC')
      }
    },

    getDosValue(dosValue) {
      let start = dosValue.indexOf('/') + 1 // find first occurrence of / and treat it as a starting point
      let end = dosValue.length // get length of the dosValue and treat it as end point.

      let updatedDosValue = dosValue.slice(start, end)

      return updatedDosValue.trim() // return v22 or v23 or v24 respectively from the chosen value.
    },

    onChangeAgeInput(value) {
      this.age = value
      this.debounceInput('age')
    },
    setDosYear() {
      if (this.hasHhsData) {
        this.DefaultdrfValue = 'ADULT'
      } else if (this.hasV05Data) {
        this.DefaultdrfValue = 'D1'
      } else if (this.hasv21ESRDData) {
        this.DefaultdrfValue = 'C1'
      } else if (this.hasv24ESRDData) {
        this.DefaultdrfValue = 'C3'
      } else {
        this.DefaultdrfValue = 'CN'
      }
      this.elasticSearch('typeChange')
    },
    handleLazyLoading({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 150) {
        if (this.isActive == false && this.endData == false)
          this.elasticSearch()
      }
    },
    elasticSearch(e) {
      if (e == 'typeChange') {
        this.totalEntries = 0
        this.tableData = {}
        this.endData = false
      }
      let drfValue = this.returnSearchDrfValue
      this.changeTable()
      this.isActive = true
      let search_data = {
        dx_hcc: this.codingValue,
        dos_year: this.getDosValue(this.dosValue),
        drf: drfValue,
        search: this.searchTerm,
        start: this.totalEntries,
      }
      this.axios.post(this.search_url, search_data).then((response) => {
        this.isActive = false
        if (typeof response.data == 'object') {
          if (Object.keys(response.data).length < 20) this.endData = true
          for (let key in Object.keys(response.data)) {
            this.tableData[Number(this.totalEntries) + Number(key)] =
              response.data[key]
          }
          this.totalEntries = Object.keys(this.tableData).length
        }
      })
    },
    resetSearch() {
      /*query reset function clearing the input list,
      passing space on debounce function, 
      setting it to intital value*/
      Object.assign(this.$data, this.$options.data.call(this))
      this.debounceInput(' ')
      this.$emit('confirmAction')
    },
    fillSelectedDx() {
      localStorage.setItem('selectedSearchData', '')
      this.selectedDxData.map((data) => {
        this.searchCodeData.push(data.code)
      })
      localStorage.setItem('selectedSearchData', this.searchCodeData)
      this.resetSearch() //before emit resets all the veriable
    },
    changeSearch(item_code) {
      this.queryReset = item_code
      this.codingValue = 'dx'
      this.debounceInput(this.queryReset)
      this.elasticSearch()
    },
  },
}
</script>

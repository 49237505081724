<template>
  <div class="driskFactor">
    <template v-if="hasHhsData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="hhsItems"
        :value="hhsItems[0]"
        @change="onChange"
        color="#0099d9"
        style="color: rgb(84, 84, 84) !important;"
      ></v-select>
    </template>
    <template v-else-if="hasV05Data">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v05Items"
        :value="v05Items[0]"
        @change="onChange"
        style="color: rgb(84, 84, 84) !important;"
        color="#0099d9"
      ></v-select>
    </template>
    <template v-else-if="hasv21ESRDData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v21ESRDItems"
        :value="v21ESRDItems[0]"
        @change="onChange"
        style="color: rgb(84, 84, 84) !important;"
        color="#0099d9"
      ></v-select>
    </template>
    <template v-else-if="hasv24ESRDData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v24ESRDItems"
        :value="v24ESRDItems[0]"
        @change="onChange"
        style="color: rgb(84, 84, 84) !important;"
        color="#0099d9"
      ></v-select>
    </template>
    <template v-else>
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="items"
        :value="riskModel"
        @change="onChange"
        style="color: rgb(84, 84, 84) !important;"
        color="#0099d9"
      ></v-select>
    </template>
  </div>
</template>

<script>
import riskModelVItems from '../../data/riskModelVItems.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import store from '../../store'

export default {
  props: {
    componentDataIndex: Number,
    riskModel: String,
    dosYear: String,
  },
  data() {
    return {
      items: riskModelVItems,
      v05Items: riskModelV05Items,
      hhsItems: riskModelHHSItems,
      v21ESRDItems: riskModelESRDV21Items,
      v24ESRDItems: riskModelESRDV24Items,
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onRiskModel', value)
    },
  },
  computed: {
    hasHhsData() {
      return store.getters.hasHhsData(this.dosYear)
    },
    hasV05Data() {
      return store.getters.hasV05Data(this.dosYear)
    },
    hasv21ESRDData() {
      return store.getters.hasv21ESRDData(this.dosYear)
    },
    hasv24ESRDData() {
      return store.getters.hasv24ESRDData(this.dosYear)
    },
  },
}
</script>

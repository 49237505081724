<template>
  <v-card>
    <div class="search-card">
      <v-btn
        v-bind:style="[
          current_path != '/hccreference/'
            ? current_path != '/'
              ? { display: 'none' }
              : { display: 'block' }
            : { display: 'block' },
        ]"
        small
        class="close-btn-search"
        @click="resetSearch()"
        fab
        right
        absolute
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="search-box">
        <div
          v-bind:style="[
            current_path != '/hccreference/'
              ? current_path != '/'
                ? { width: '140%' }
                : { width: '100%' }
              : { width: '100%' },
          ]"
        >
          <div
            class="search-menu"
            v-bind:style="[
              current_path != '/hccreference/'
                ? current_path != '/'
                  ? { width: '100% !important' }
                  : {}
                : {},
            ]"
          >
            <div class="filter-field-container search-block">
              <span class="codingChoiceLabel">
                Conditions
                <v-icon
                  color="black"
                  class="p-1"
                  size="12"
                  dark
                  v-tippy="{
                    theme: 'epicc',
                    arrow: true,
                    content: tooltipDataForCondition,
                  }"
                  >fas fa-question-circle</v-icon
                >
              </span>
              <v-text-field
                class="dxTextField"
                placeholder="Search here"
                color="#0099d9"
                dense
                flat
                outlined
                v-model="queryReset"
                v-on:input="debounceInput"
                autofocus
              ></v-text-field>
            </div>

            <div
              class="filter-field-container"
              v-bind:style="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? { 'padding-left': '16px' }
                    : { width: '100%' }
                  : { width: '100%' },
              ]"
            >
              <span class="codingChoiceLabel">DX/HCC</span>
              <v-select
                class="search-coding-choice"
                flat
                dense
                color="#0099d9"
                style="color: #545454"
                outlined
                :items="codingChoices"
                v-model="codingValue"
                @change="elasticSearch('typeChange')"
              />
            </div>
            <div class="filter-field-container">
              <span class="search-dos-choice">DOS Year/Model</span>
              <v-select
                class="search-dos-choice"
                flat
                dense
                outlined
                style="color: #545454"
                color="#0099d9"
                :items="dosChoices"
                v-model="dosValue"
                @change="setDosYear"
              ></v-select>
            </div>
            <div class="filter-field-container">
              <span class="drfChoicelabel">Risk Model</span>
              <template v-if="hasHhsData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfhhsItems"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasV05Data">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  style="color: #545454"
                  outlined
                  :items="drfv05Items"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasv21ESRDData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfvEsrdV21Items"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasv24ESRDData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfEsrdv24Items"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else>
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfItems"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
            </div>
            <div class="filter-field-container age-block">
              <span class="drfChoicelabel">Age</span>
              <v-text-field
                :value="age"
                dense
                outlined
                type="number"
                class="agetextfield"
                @input="onChangeAgeInput"
                color="#0099d9"
                :error-messages="invalidAge ? ['Enter valid age'] : []"
                autocomplete="off"
              ></v-text-field>
            </div>
          </div>
          <div class="search-result-box" @scroll="handleLazyLoading">
            <v-progress-circular
              indeterminate
              color="red"
              v-bind:class="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? 'loaderStyleRef'
                    : 'loaderStyle'
                  : 'loaderStyle',
              ]"
              v-if="isActive == true"
            ></v-progress-circular>
            <div
              class="message-over-search"
              v-bind:style="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? { display: 'none' }
                    : { display: 'block' }
                  : { display: 'block' },
              ]"
            >
              <p>Click row to add condition to selection</p>
            </div>

            <table class="search-table">
              <tr>
                <th
                  v-if="isCodingValueSetToDX"
                  class="search-header-th-left search-inner-th-individual"
                  style="width:19.1%"
                >
                  DX Code
                </th>
                <th
                  v-if="isCodingValueSetToHCC"
                  class="search-header-th-left search-inner-th-individual"
                  style="width:19.1%"
                >
                  HCC
                  <span v-if="current_path == '/hccreference'">
                    <v-icon
                      color="black"
                      class="p-1"
                      size="12"
                      dark
                      v-tippy="{
                        theme: 'epicc',
                        arrow: true,
                        content: tooltipDataForHccRef,
                      }"
                      >fas fa-question-circle</v-icon
                    >
                  </span>
                </th>
                <th class="search-inner-th-individual" style="width:38.6%">
                  Description
                </th>
                <th
                  v-if="isCodingValueSetToDX"
                  class="search-inner-th-individual"
                  style="width:15.7%"
                >
                  HCC
                </th>
                <th
                  v-if="hasHhsData"
                  class="search-inner-th-individual"
                  style="width:15.7%"
                >
                  Plate
                </th>
                <th
                  v-if="isCodingValueSetToHCC"
                  class="search-inner-th-individual"
                  style="width:15.7%"
                >
                  Trump Codes
                </th>
                <th class="search-inner-th-individual" style="width:18.5%">
                  RAF
                </th>
                <th
                  class="search-inner-th-individual"
                  style="width:25.5%"
                  v-bind:class="[
                    current_path != '/hccreference/'
                      ? current_path != '/'
                        ? 'search-header-th-right'
                        : 'search-header-th-right'
                      : 'search-header-th-right',
                  ]"
                >
                  Currently Selected
                </th>
              </tr>

              <tr
                v-for="item in tableData"
                :key="item.code"
                v-bind:class="{
                  selectedRow: item.code === selectedItem.code,
                }"
              >
                <td style="cursor: pointer;" @click="selectDx(item)">
                  <p class="search-table-left" v-if="isCodingValueSetToDX">
                    {{ item.code }}
                  </p>
                  <p v-if="isCodingValueSetToHCC" class="search-table-left">
                    <span
                      v-if="current_path == '/hccreference'"
                      @click="changeSearch(item.code)"
                      >{{ item.code }}</span
                    >
                    <span v-else>{{ item.code }}</span>
                  </p>
                </td>
                <td
                  class="text-xs-right"
                  style="cursor: pointer;"
                  @click="selectDx(item)"
                >
                  <p class="search-table-left">{{ item.description }}</p>
                </td>
                <td
                  v-if="isCodingValueSetToDX"
                  style="cursor: pointer;"
                  @click="selectDx(item, 'hcc')"
                >
                  <span v-for="hcc in item.hcc" :key="hcc">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" @mouseover="showHccDetail(hcc)">
                          {{ hcc }}
                          <span
                            v-bind:style="[
                              item.hcc.length > 1
                                ? item.hcc.lastIndexOf(hcc) ==
                                  item.hcc.length - 1
                                  ? { display: 'none' }
                                  : {}
                                : { display: 'none' },
                            ]"
                            >,</span
                          >
                        </span>
                      </template>
                      <v-progress-circular
                        indeterminate
                        color="white"
                        :size="20"
                        v-if="isHoverActive == true"
                      ></v-progress-circular>
                      <span v-else>{{ onHoverData }}</span>
                    </v-tooltip>
                  </span>
                </td>
                <td v-if="hasHhsData">
                  <p class="search-table-left">
                    {{ item.plate }}
                  </p>
                </td>
                <td v-if="isCodingValueSetToHCC" class="text-xs-right">
                  <span v-for="trump in item.trumps" :key="trump">
                    <span>
                      {{ trump }}
                      <span
                        v-bind:style="[
                          item.trumps.length > 1
                            ? item.trumps.lastIndexOf(trump) ==
                              item.trumps.length - 1
                              ? { display: 'none' }
                              : {}
                            : { display: 'none' },
                        ]"
                        >,</span
                      >
                    </span>
                  </span>
                </td>
                <td>
                  <p class="search-table-right">{{ item.raf }}</p>
                </td>
                <td
                  v-bind:style="[
                    current_path != '/hccreference/'
                      ? current_path != '/'
                        ? { border: 'none', 'background-color': 'white' }
                        : {
                            padding: '10px',
                            display: table - cell,
                          }
                      : {
                          display: 'flex',
                          'justify-content': 'center',
                          'align-items': 'center',
                          padding: '10px',
                        },
                  ]"
                >
                  <div class="tickmark-container">
                    <div
                      v-for="data in selectedDxData"
                      v-bind:key="data.code"
                      v-bind:style="[
                        current_path != '/hccreference/'
                          ? current_path != '/'
                            ? { display: 'none' }
                            : {
                                display: 'flex',
                                'justify-content': 'center',
                                'align-items': 'center',
                              }
                          : checkSelectCondition(item, data)
                          ? {
                              display: 'flex',
                              'justify-content': 'center',
                              'align-items': 'center',
                            }
                          : {
                              display: 'none',
                            },
                      ]"
                    >
                      <p
                        class="tickmark"
                        v-if="checkSelectCondition(item, data)"
                      >
                        <i class="fas fa-check"></i>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="selected_dx_box"
          v-bind:style="[
            current_path != '/hccreference/'
              ? current_path != '/'
                ? { display: 'none' }
                : { display: 'block' }
              : { display: 'block' },
          ]"
        >
          <div class="selectedDxBox">
            <div
              v-if="selectedRowsIDs.length == 0"
              class="no-condition-selected"
            >
              <p>No Conditions Selected</p>
            </div>
            <div v-else class="selectDxTable">
              <p class="selected-condition">Selected Conditions</p>
              <p class="message-over-search-select">
                Click
                <i class="fas fa-times"></i> to remove condition from list.
              </p>
              <div class="selected-table">
                <table class="selected-table-table">
                  <tr class="selected-table-head-tr">
                    <th
                      style="width: 29%;border-right: 1px solid white;"
                      class="search-header-th-left"
                    >
                      Code
                    </th>
                    <th style="width: 59%;" class="search-inner-th-individual">
                      Description
                    </th>
                    <th style="width: 59%;" class="search-header-th-right">
                      Remove
                    </th>
                  </tr>

                  <tr
                    v-for="item in selectedDxData"
                    :key="item.name"
                    class="selected-table-data-tr"
                  >
                    <td v-if="item.hcc_selected == true">
                      <span v-for="hcc in item.hcc" :key="hcc">
                        {{ hcc }}
                        <span
                          v-bind:style="[
                            item.hcc.length > 1
                              ? item.hcc.lastIndexOf(hcc) == item.hcc.length - 1
                                ? { display: 'none' }
                                : {}
                              : { display: 'none' },
                          ]"
                          ><br
                        /></span>
                      </span>
                    </td>
                    <td v-else>{{ item.code }}</td>
                    <td
                      v-if="item.hcc_selected == true"
                      class="text-xs-right"
                      v-html="getHccDescription(item)"
                    ></td>
                    <td v-else class="text-xs-right">
                      {{ item.description.substring(0, 60) + '..' }}
                    </td>
                    <td>
                      <div
                        v-for="data in selectedDxData"
                        v-bind:key="data.code"
                        class="tickmark"
                      >
                        <p
                          v-if="item.code == data.code"
                          style="cursor: pointer;"
                          @click="removeDx(item)"
                          :class="{
                            removeDxRow: item.code === selectedItem.code,
                          }"
                        >
                          <i class="fas fa-times"></i>
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="text-align: right; margin-right:25px;">
              <v-btn
                v-if="selectedRowsIDs.length != 0"
                class="confirm-selected-button submit button"
                color="info"
                @click="fillSelectedDx"
                >{{ confirm }}</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-snackbar
      min-width="auto"
      v-model="snackbar"
      :timeout="timeout"
      class="snackbar"
    >
      <div class="snackbar-text">{{ message }}</div>
    </v-snackbar>
  </v-card>
</template>

<style scoped src="../../assets/css/search.css"></style>

<script>
import riskModelVItems from '../../data/riskModelVItemsSearch.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import dosYearData from '../../data/dosYearData.json'
import _ from 'lodash'
import store from '../../store'

var LRU = require('lru-cache')
var options = {
  max: 500,
  length: function(n, key) {
    return n * 2 + key.length
  },
  maxAge: 1000 * 60 * 60,
}
var cache = new LRU(options)
export default {
  data() {
    return {
      age: 65,
      current_path: window.location.pathname,
      onHoverData: '',
      searchCodeData: [],
      searchTerm: '',
      queryReset: '',
      tooltipDataForHccRef: 'Click on Hcc to show all the related Dx Codes',
      codingChoices: [
        { text: 'DX', value: 'dx' },
        { text: 'HCC', value: 'hcc' },
      ],
      codingValue: 'dx', // default coding method
      dosChoices: dosYearData,
      buttonStyle: {
        cursor: 'default',
      },
      dosValue: 'v24_2022', // default DOS Value
      confirm: 'Confirm',
      dxTableHeaders: [
        {
          text: 'DX Code',
          sortable: false,
          value: 'code',
        },
        { text: 'Description', value: 'description' },
        { text: 'HCC', value: 'hcc' },
        { text: 'RAF', value: 'raf' },
        { text: 'Select', value: 'Select', class: 'blankColHeader' },
      ],
      local: [],
      dxData: [],
      selectedDxTableHeaders: [
        {
          text: 'DX Code',
          sortable: false,
          value: 'code',
        },
        { text: 'Description', value: 'description' },
      ],
      selectedDxData: [],
      selectedRowsIDs: [],
      selectedItem: -1,
      tableData: [],
      drfItems: riskModelVItems,
      drfv05Items: riskModelV05Items,
      drfhhsItems: riskModelHHSItems,
      drfvEsrdV21Items: riskModelESRDV21Items,
      drfEsrdv24Items: riskModelESRDV24Items,
      v05Data: [
        'v08_2024',
        'v08_2023',
        'v08_2022',
        'v05_2021',
        'v05_2020',
        'v05_2019',
        'v05_2018',
        'v05_2017',
      ],
      vData: [
        'v28_2024',
        'v24_2024',
        'v28_2023',
        'v24_2023',
        'v24_2022',
        'v24_2021',
        'v24_2020',
        'v24_2019',
        'v23_2018',
        'v22_2017',
      ],
      hhsData: [
        'hhs_v07_2024',
        'hhs_v07_2023',
        'hhs_v07_2022',
        'hhs_v05_2021',
        'hhs_v05_2020',
        'hhs_v05_2019',
        'hhs_v05_2018',
      ],
      v21_esrd_data: ['esrd_v21_2020', 'esrd_v21_2021'],
      v24_esrd_data: ['esrd_v24_2022', 'esrd_v24_2023', 'esrd_v24_2024'],
      DefaultdrfValue: 'CN',
      search_url: '/api/search',
      hover_url: '/api/search/hover',
      errorMessage: 'Enter valid age',
      isActive: false,
      dialog: false,
      totalEntries: 0,
      endData: false,
      tooltipDataForCondition:
        'Conditions eligible as per selected dos year model.',
      timeout: 4000,
      message: 'Codes are already selected',
      snackbar: false,
      isHoverActive: false,
    }
  },
  computed: {
    hasHhsData() {
      return this.hhsData.includes(this.getDosValue(this.dosValue))
    },
    hasV05Data() {
      return this.v05Data.includes(this.getDosValue(this.dosValue))
    },
    hasv21ESRDData() {
      return this.v21_esrd_data.includes(this.getDosValue(this.dosValue))
    },
    hasv24ESRDData() {
      return this.v24_esrd_data.includes(this.getDosValue(this.dosValue))
    },
    isCodingValueSetToHCC() {
      return store.getters.hccCodingValue(this.codingValue)
    },
    isCodingValueSetToDX() {
      return store.getters.dxCodingValue(this.codingValue)
    },

    returnSearchDrfValue() {
      let defaultDrf = this.DefaultdrfValue
      let finalDrfValue = ''
      let search_v21_esrd = {
        C1: 'CG',
        C2: 'CG',
        D: 'CED',
        I2: 'IG',
        G1: 'KT',
        G2: 'KT',
        ED: 'NED',
        E2: 'E2',
      }
      let search_v24_esrd = {
        C3: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C4: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C5: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C6: parseInt(this.age) < 65 ? 'GFD' : 'GFA',
        C7: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        C8: parseInt(this.age) < 65 ? 'GND' : 'GNA',
        D1: 'CED',
        D2: 'CED',
        ED: 'NED',
        E1: 'E1',
        E2: 'E2',
        G1: 'KT',
        G2: 'KT',
        I3: 'CEDI',
        I4: 'CEDI',
        I5: 'GI',
        I6: 'GI',
        I7: 'GI',
        I8: 'GI',
        I9: 'GI',
        IA: 'GI',
      }
      let search_v05 = {
        D1: parseInt(this.age) < 65 ? 'CNN' : 'CAN',
        D2: parseInt(this.age) < 65 ? 'CLN' : 'CLA',
        D3: 'INS',
        D4: 'NEN',
        D5: 'NEN',
        D6: 'NEL',
        D7: 'NEL',
        D8: 'NEI',
        D9: 'NEI',
      }
      let search_vData = {
        CN: parseInt(this.age) < 65 ? 'CND' : 'CNA',
        CP: parseInt(this.age) < 65 ? 'CPD' : 'CPA',
        CF: parseInt(this.age) < 65 ? 'CFD' : 'CFA',
        I: 'INS',
      }

      if (defaultDrf in search_vData) {
        finalDrfValue = search_vData[defaultDrf]
      } else if (defaultDrf in search_v05) {
        finalDrfValue = search_v05[defaultDrf]
      } else if (defaultDrf in search_v21_esrd) {
        finalDrfValue = search_v21_esrd[defaultDrf]
      } else if (defaultDrf in search_v24_esrd) {
        finalDrfValue = search_v24_esrd[defaultDrf]
      } else {
        finalDrfValue = defaultDrf
      }
      return finalDrfValue
    },
    invalidAge: {
      get: function() {
        parseInt(this.age) > 0 ? this.$emit('validAge', true) : false
        return this.isAgeInvalid
      },
      set: function(value) {
        this.isAgeInvalid = value
      },
    },
  },
  methods: {
    debounceInput: _.debounce(function(e) {
      if (e !== 'age') {
        this.searchTerm = e
        this.totalEntries = 0
        this.tableData = {}
        this.endData = false
      }
      this.elasticSearch()
    }, 500),
    showHccDetail(item) {
      /*This fucntion is using caching technique to show data on search hover 
      and also fetch data from /api/hover if data is not avail on its cache*/
      const item_hcc = item
      if (cache.keys().includes(item_hcc)) {
        this.onHoverData = cache.get(item_hcc)
      } else {
        this.isHoverActive = true
        let hover_data = {
          dx_hcc: 'hcc',
          dos_year: this.dosValue,
          drf: this.DefaultdrfValue,
          hcc: item_hcc,
        }
        this.axios.post(this.hover_url, hover_data).then((response) => {
          if (typeof response.data == 'object') {
            let data_recevied = Object.assign({}, response.data)
            this.onHoverData = data_recevied.description
            cache.set(data_recevied.code, data_recevied.description)
          }
          this.isHoverActive = false
          this.snackbar = false
        })
      }
    },
    getHccDescription(item) {
      let result = ''
      item.hcc.forEach((hcc) => {
        result += `${cache.get(hcc)}<br>`
      })
      return result
    },
    selectDx(item, label = 'dx') {
      if (!this.isActive && !this.isHoverActive) {
        if (label == 'hcc') {
          if (
            item.hcc.every((hcc) =>
              this.selectedDxData.some((selected_item) =>
                selected_item.hcc.includes(hcc)
              )
            )
          ) {
            this.message = 'Codes are already selected'
            this.snackbar = true
            return null
          } else {
            this.selectedItem = item
            this.$set(item, 'selected', true)
            this.$set(item, 'hcc_selected', true)
            item.hcc.forEach((hcc) => this.showHccDetail(hcc))
          }
        } else {
          this.selectedItem = item
          this.$set(item, 'selected', true)
        }
        if (
          this.snackbar == false &&
          this.selectedRowsIDs.indexOf(item.code) === -1
        ) {
          this.selectedRowsIDs.push(item.code)
          this.selectedDxData.push(item)
        }
      } else {
        this.message = 'Please wait for data to load'
        this.snackbar = true
      }
    },
    checkSelectCondition(item, data) {
      return item.code == data.code
    },
    removeDx(item) {
      this.$set(item, 'hcc_selected', false)
      var index0 = this.selectedRowsIDs.indexOf(item.code)
      var index = this.selectedDxData.indexOf(item)
      if (index0 > -1) {
        this.$delete(this.selectedDxData, index)
        this.$delete(this.selectedRowsIDs, index0)
      }
    },
    changeTable() {
      if (this.codingValue == 'hcc') {
        this.$set(this.dxTableHeaders[0], 'text', 'HCC')
        this.$set(this.dxTableHeaders[2], 'text', 'Trump Codes')
      }
      if (this.codingValue == 'dx') {
        this.$set(this.dxTableHeaders[0], 'text', 'DX Code')
        this.$set(this.dxTableHeaders[2], 'text', 'HCC')
      }
    },

    getDosValue(dosValue) {
      let start = dosValue.indexOf('/') + 1 // find first occurrence of / and treat it as a starting point
      let end = dosValue.length // get length of the dosValue and treat it as end point.

      let updatedDosValue = dosValue.slice(start, end)
      return updatedDosValue.trim() // return v22 or v23 or v24 respectively from the chosen value.
    },
    onChangeAgeInput(value) {
      this.age = value
      this.debounceInput('age')
    },
    setDosYear() {
      if (this.hhsData.includes(this.getDosValue(this.dosValue))) {
        this.DefaultdrfValue = 'ADULT'
      } else if (this.v05Data.includes(this.getDosValue(this.dosValue))) {
        this.DefaultdrfValue = 'D1'
      } else if (this.v21_esrd_data.includes(this.getDosValue(this.dosValue))) {
        this.DefaultdrfValue = 'C1'
      } else if (this.v24_esrd_data.includes(this.getDosValue(this.dosValue))) {
        this.DefaultdrfValue = 'C3'
      } else {
        this.DefaultdrfValue = 'CN'
      }
      this.elasticSearch('typeChange')
    },
    handleLazyLoading({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 150) {
        if (this.isActive == false && this.endData == false)
          this.elasticSearch()
      }
    },
    elasticSearch(e) {
      if (e == 'typeChange') {
        this.totalEntries = 0
        this.tableData = {}
        this.endData = false
      }
      let drfValue = this.returnSearchDrfValue
      this.changeTable()
      this.isActive = true
      let search_data = {
        dx_hcc: this.codingValue,
        dos_year: this.getDosValue(this.dosValue),
        drf: drfValue,
        search: this.searchTerm,
        start: this.totalEntries,
      }
      this.axios.post(this.search_url, search_data).then((response) => {
        this.isActive = false
        if (typeof response.data == 'object') {
          if (Object.keys(response.data).length < 20) this.endData = true
          for (let key in Object.keys(response.data)) {
            this.tableData[Number(this.totalEntries) + Number(key)] =
              response.data[key]
          }
          this.totalEntries = Object.keys(this.tableData).length
        }
      })
    },
    resetSearch() {
      /*query reset function clearing the input list,
      passing space on debounce function, 
      setting it to intital value*/
      Object.assign(this.$data, this.$options.data.call(this))
      this.debounceInput(' ')
      this.$emit('confirmAction')
    },
    fillSelectedDx() {
      localStorage.setItem('selectedSearchData', '')
      this.selectedDxData.map((data) => {
        if (data.hcc_selected == true) this.searchCodeData.push(data.hcc)
        else this.searchCodeData.push(data.code)
      })
      localStorage.setItem('selectedSearchData', this.searchCodeData)
      this.resetSearch() //before emit resets all the veriable
    },
    changeSearch(item_code) {
      this.queryReset = item_code
      this.codingValue = 'dx'
      this.debounceInput(this.queryReset)
      this.elasticSearch()
    },
  },
}
</script>

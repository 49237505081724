var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main pt-5 member-box"},[_c('div',{staticClass:"member-box-between"},[_c('div',{staticClass:"member-box-label condition-text"},[_vm._v(" Conditions "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForCondition,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForCondition,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box conditioninput"},[_c('div',{staticStyle:{"position":"relative"}},[_c('codeinput',{ref:"revalidateCodeInput",attrs:{"tagList":_vm.memberProfileData.inputDict.memberProfileConditions,"tagstring":_vm.tag,"searchBox":"tag1","componentDataIndex":_vm.componentDataIndex,"isClearTag":_vm.isClear,"riskModel":_vm.memberProfileData.inputDict.riskModel,"dosYearModel":_vm.memberProfileData.inputDict.dosYearModel,"isEmptyTagList":_vm.isEmptyTags,"commandType":_vm.memberProfileData.inputDict.commandType},on:{"getTag":_vm.updateTagList,"removeTag":_vm.updateTagList,"clearTagFlag":function($event){_vm.isClear = false},"invalidChips":_vm.getInvalidChips,"emptyTagsFlag":function($event){_vm.isEmptyTags = false}}}),(this.$store.state.search_box == 'tag1')?_c('div',{staticClass:"member-search-suggestion",class:[
              this.$store.state.search_member.length > 0 &&
              this.$store.state.search_box == 'tag1'
                ? 'suggestion-box'
                : '',
              this.$store.state.search_box,
              (this.$store.state.search_suggestion_loader &&
                this.$store.state.search_box == 'tag1') == true
                ? 'show-box'
                : '',
            ]},[(this.$store.state.search_suggestion_loader == true)?_c('v-progress-circular',{staticClass:"member-search-loader",attrs:{"indeterminate":"","color":"red","size":"32"}}):_vm._e(),(this.$store.state.search_box == 'tag1')?_c('ul',_vm._l((this.$store.state.search_member),function(item){return _c('li',{key:item,on:{"click":function($event){return _vm.addTagFromList(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()],1):_vm._e()],1)])]),_c('div',{staticClass:"member-box-between"},[_c('div',{staticClass:"member-box-label agelabel"},[_vm._v(" Age: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForAge,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForAge,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box ageinput"},[_c('ageField',{attrs:{"age":_vm.memberProfileData.inputDict.age,"isAgeInvalid":_vm.isAgeInvalid,"disableAge":_vm.disableAge},on:{"onAge":_vm.getAge,"validAge":function($event){_vm.isAgeInvalid = false}}})],1),_c('div',{staticClass:"member-box-label member-box-sec-row"},[_vm._v("Gender:")]),_c('div',{staticClass:"box genderinput"},[_c('gender',{attrs:{"gender":_vm.memberProfileData.inputDict.gender},on:{"onGender":_vm.getGender}})],1)]),_c('div',{staticClass:"member-box-between member-box-mobile"},[_c('div',{staticClass:"member-box-label"},[_vm._v("DOS Year/Model:")]),_c('div',{staticClass:"box dRiskinput"},[_c('dosYearModel',{attrs:{"dosYearModel":_vm.memberProfileData.inputDict.dosYearModel},on:{"onDosYear":_vm.getDosYear}})],1),_c('div',{staticClass:"member-box-label member-box-sec-row"},[_vm._v(" Original reason for entitlement : "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForOrec,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForOrec,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box oceinput"},[_c('oreComponent',{attrs:{"ore":_vm.memberProfileData.inputDict.ore},on:{"onOre":_vm.getOre}})],1)]),_c('div',{staticClass:"member-box-between risk-model-col"},[_c('div',{staticClass:"member-box-label"},[_vm._v(" Risk Model: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForRiskModel,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForRiskModel,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box dosyearinput"},[_c('riskModelField',{attrs:{"dosYear":_vm.memberProfileData.inputDict.dosYearModel,"riskModel":_vm.memberProfileData.inputDict.riskModel},on:{"onRiskModel":_vm.getRiskModel}})],1),_c('div',{staticClass:"member-box-label member-box-sec-row"},[_vm._v(" Base Rate : "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForBaserate,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForBaserate,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box baserateinput"},[_c('baseRateInput',{attrs:{"baseRate":_vm.memberProfileData.inputDict.baseRate,"isBaseRateInvalid":_vm.isBaseRateInvalid},on:{"onBaseRate":_vm.getBaseRate,"validBaseRate":function($event){_vm.isBaseRateInvalid = false}}})],1)]),_c('div',{staticClass:"member-box-between"},[_c('div',{staticClass:"member-box-label agelabel"},[_vm._v(" Date Of Birth: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForDob,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForDob,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box calculationyear-input"},[_c('dobInput',{attrs:{"calculationYear":_vm.memberProfileData.inputDict.calculationYear,"dateOfBirthValue":_vm.memberProfileData.inputDict.DOB},on:{"dateOfBirthUpdate":_vm.setDateOfBirth}})],1)]),_c('div',{staticClass:"member-box-between"},[_c('div',{staticClass:"member-box-label agelabel"},[_vm._v(" Plate: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForPlate,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForPlate,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box dosyearinput"},[_c('plateInput',{attrs:{"plateValue":_vm.memberProfileData.inputDict.plate},on:{"updatePlateValue":_vm.setPlateValue}})],1)]),_c('div',{staticClass:"member-box-between member-box-mobile"},[_c('div',{staticClass:"member-box-label agelabel"},[_vm._v(" Calculation Year: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForCalculationYear,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForCalculationYear,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box calculationyear-input"},[_c('calculationYearInput',{attrs:{"error":_vm.dobErrorMessage,"dob":_vm.memberProfileData.inputDict.DOB,"calculationYear":_vm.memberProfileData.inputDict.calculationYear},on:{"calculationYearUpdate":_vm.setCalculationYear}})],1)]),_c('div',{staticClass:"member-box-between risk-model-col"},[_c('div',{staticClass:"member-box-label platelabel"},[_vm._v("Enrollment Duration:")]),_c('div',{staticClass:"box enrollmentinput"},[_c('enrollmentInput',{attrs:{"enrollmentDuration":_vm.memberProfileData.inputDict.enrollmentDuration},on:{"updateEnrollmentValue":_vm.setEnrollmentValue}})],1)]),_c('div',{staticClass:"submitclear submitclear-member"},[_c('v-btn',{staticClass:"submit button",on:{"click":_vm.submit}},[_vm._v("Submit")]),_c('v-btn',{staticClass:"clear button",on:{"click":_vm.clearResult}},[_vm._v("Clear")]),_c('v-btn',{ref:"addBtn",class:[
          'add button ' +
            (_vm.componentDataIndex ==
              this.$store.state.componentData.length - 1 &&
            this.$store.state.componentData[_vm.componentDataIndex]
              .isSubmitButtonClicked
              ? 'showBtn'
              : ''),
        ],on:{"click":_vm.addRow}},[_vm._v("Add new")]),(_vm.componentDataIndex !== 0)?_c('v-btn',{staticClass:"close button",on:{"click":function($event){return _vm.deleteRow()}}},[_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: 'Close',
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: 'Close',\n          }"}],staticClass:"p-1",attrs:{"color":"white","size":"20","dark":""}},[_vm._v("fas fa-trash")])],1):_vm._e(),_c('v-snackbar',{staticClass:"snackbar",model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticClass:"snackbar-text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-btn',{staticClass:"snackbar-button",attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1),_c('div',{staticClass:"progress-bar"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWaitingForResult),expression:"isWaitingForResult"}],attrs:{"color":"light-blue","indeterminate":"","rounded":"","height":"6"}})],1),(_vm.APIResponse.length != 0)?_c('resultTable',{attrs:{"componentDataIndex":_vm.componentDataIndex}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }